import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  Box,
  Card,
  CardContent,
  useTheme,
  useMediaQuery
} from '@mui/material';
import Sidebar from "../../../components/Sidebar";
import instance from "../../../globals/axios";  // Add this import
import axios from 'axios';  // Add this import

const SkillTransactions = () => {
  const iveriId = localStorage.getItem("iveri_id");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tokenDetails, setTokenDetails] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchTokenDetails = async () => {
    try {
      const response = await axios.get(`http://token8.asqi.in/asset/assets/${iveriId}`);
      setTokenDetails(response.data);
    } catch (err) {
      console.error('Error fetching token details:', err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [transactionsResponse] = await Promise.all([
          instance.get('skill-transactions/'),
          fetchTokenDetails()
        ]);
        setTransactions(transactionsResponse.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <>
        <Sidebar />
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <CircularProgress />
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Sidebar />
        <Container>
          <Typography color="error" sx={{ mt: 3 }}>{error}</Typography>
        </Container>
      </>
    );
  }

  const MobileCard = ({ row }) => (
    <Card sx={{ mb: 2, boxShadow: 2 }}>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle2" color="textSecondary">
            Skills
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {row.skill}
          </Typography>
        </Box>

        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle2" color="textSecondary">
            Transaction ID
          </Typography>
          <Link 
            href={`http://token8.asqi.in/asset/assets/${row.transaction}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ 
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
              fontSize: '0.875rem'
            }}
          >
            {row.transaction}
          </Link>
        </Box>

        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle2" color="textSecondary">
            IPFS Hash
          </Typography>
          <Typography variant="body2">
            {tokenDetails?.fixed_attributes_ipfs_hash || 'Not available'}
          </Typography>
        </Box>

        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle2" color="textSecondary">
            Token ID
          </Typography>
          <Typography variant="body2">
            {tokenDetails?.token_code || 'Not available'}
          </Typography>
        </Box>

        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle2" color="textSecondary">
            Status
          </Typography>
          <Box
            sx={{
              backgroundColor: row.status === 0 ? '#e8f5e9' : '#ffebee',
              color: row.status === 0 ? '#2e7d32' : '#c62828',
              padding: '4px 8px',
              borderRadius: '4px',
              display: 'inline-block',
              mt: 0.5
            }}
          >
            {row.status === 0 ? 'Active' : 'Inactive'}
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2" color="textSecondary">
            Date
          </Typography>
          <Typography variant="body2">
            {row.createdAt}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Sidebar />
      <Container 
        maxWidth={isMobile ? "xs" : "lg"} 
        style={{ marginTop: "30px" }}
      >
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 3, 
            fontWeight: "600",
            fontSize: isMobile ? "20px" : "24px" 
          }}
        >
          Skills Blockchain Transactions
        </Typography>

        {isMobile ? (
          <Box>
            {transactions.map((row, index) => (
              <MobileCard key={index} row={row} />
            ))}
          </Box>
        ) : (
          <TableContainer 
            component={Paper}
            sx={{
              overflowX: 'auto',
              '& .MuiTable-root': {
                minWidth: 650
              }
            }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#262262' }}>
                  <TableCell sx={{ color: 'white' }}>Skills</TableCell>
                  <TableCell sx={{ color: 'white' }}>Transaction ID</TableCell>
                  <TableCell sx={{ color: 'white' }}>IPFS Hash</TableCell>
                  <TableCell sx={{ color: 'white' }}>Token ID</TableCell>
                  <TableCell sx={{ color: 'white' }}>Status</TableCell>
                  <TableCell sx={{ color: 'white' }}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.skill}</TableCell>
                    <TableCell>
                      <Link 
                        href={`http://token8.asqi.in/asset/assets/${row.transaction}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ 
                          textDecoration: 'none',
                          '&:hover': { textDecoration: 'underline' }
                        }}
                      >
                        {row.transaction}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: '0.875rem' }}>
                        {tokenDetails?.fixed_attributes_ipfs_hash || 'Not available'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {tokenDetails?.token_code || 'Not available'}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          backgroundColor: row.status === 0 ? '#e8f5e9' : '#ffebee',
                          color: row.status === 0 ? '#2e7d32' : '#c62828',
                          padding: '4px 8px',
                          borderRadius: '4px',
                          display: 'inline-block'
                        }}
                      >
                        {row.status === 0 ? 'Active' : 'Inactive'}
                      </Box>
                    </TableCell>
                    <TableCell>{row.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </>
  );
};

export default SkillTransactions;
